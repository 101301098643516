import React, { FC, useContext, useEffect } from "react";
import { Heading, Stack, Text } from "@chakra-ui/react";
import { LayoutContext } from "../../components/layout";
import { motion } from "framer-motion";
import { PageProps } from "gatsby";

const OverviewTemplate: FC<PageProps> = props => {
  const { mapMachine } = useContext(LayoutContext);
  const [, mapSend] = mapMachine as NonNullable<typeof mapMachine>;

  useEffect(() => {
    mapSend("GO_TO_OVERVIEW");
  }, []);

  return (
    <motion.div
      key={props.path}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Stack
        position="fixed"
        bottom={{ base: 12, md: 16 }}
        left={{ base: 8, md: 16 }}
        maxWidth="sm"
        align="start"
        pointerEvents="none"
        color="white"
      >
        <Heading
          textStyle="korolevCompressed"
          fontSize={{ base: "4xl", sm: "5xl", md: "6xl" }}
          pointerEvents="all"
        >
          Wonen in de
          <br /> Delftlanden
        </Heading>

        <Text display={{ base: "none", md: "block" }} pointerEvents="all">
          De nieuwe, jonge wijk ten westen van het centrum van Emmen. De
          perfecte plek voor jong en oud die op zoek zijn naar een veilige,
          kindvriendelijke en groene woonomgeving.
        </Text>
      </Stack>
    </motion.div>
  );
};

export default OverviewTemplate;
